<template>
  <div>
    <v-row class="fill-height breadcrumb pt-4" justify="center" align="center">
      <h1 class="font-weight-bold text-h3 white--text">ABOUT US</h1>
    </v-row>

    <v-container>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
      <v-row class="pa-5 text-justify" justify="center">
        <v-col cols="12" md="11" lg="8">
          <span class="subtitle-1">
            <p>
              Kinplus Technologies is a software development company focused on
              building scalable applications and software for businesses,
              corporate organisations, and individuals.
            </p>
            <p>
              We are a team of energetic and innovative developers and
              designers, who are passionate about implementing customer needs
              with an approach focused on satisfaction and timely delivery.
            </p>
            <p>
              We provide services on Software Development, Mobile Applications,
              Shell/Console Applications, and youth empowerment through training
              and internship programs for individuals and groups who are willing
              to learn the nitty-gritty of Programming and Software Application
              Development.
            </p>
            <p>
              We have a well-structured operational model which enables our core
              values of "Brilliancy" and "Accuracy", and as well strengthens our
              development process.
            </p>
            <p>
              We preach flexibility and as such offer our clients a full
              privilege of control and management of software applications
              developed by us.
            </p>
          </span>
        </v-col>
      </v-row>

      <div class="mt-4">
        <h1 class="text-center">Why Choose Us?</h1>
        <v-row justify="center" class="mt-4">
          <v-col
            class="text-center"
            cols="12"
            sm="3"
            md="2"
            v-for="icon in icons"
            :key="icon.title"
          >
            <v-card
              flat
              height="200"
              width="200"
              title="Kinplus technologies news and events"
            >
              <v-img :src="`img/abouts/${icon.icon}.png`" contain></v-img>
              <b>{{ icon.title }}</b>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <div class="pa-4">
        <h1 class="text-center secondary--text mb-3">
          Kinplus Technologies in the Media
        </h1>
        <v-row>
          <v-col cols="12" sm="6" v-for="(card, index) in cards" :key="index">
            <a :href="card.link" target="blank">
              <v-card>
                <v-img
                  :src="`img/abouts/${card.img}`"
                  height="310"
                  cover
                ></v-img>
                <h2>{{ card.title }}</h2>
                <v-card-subtitle>{{ card.subtitle }}</v-card-subtitle>
              </v-card></a
            >
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "About",

  data() {
    return {
      breadcrumbs: [
        {
          text: "Home",
          disabled: false,
          to: { name: "Home" },
        },
        {
          text: "About",
          disabled: true,
        },
      ],

      icons: [
        { title: "Responsive Website", icon: "responsive" },
        { title: "Flexibility", icon: "flexible" },
        { title: "Easy Knowledge Based", icon: "brain" },
        { title: "Security And Speed", icon: "lock" },
        { title: "24/7 service Support", icon: "support" },
      ],

      cards: [
        {
          img: "osinbanjo.png",
          title:
            "His Excellency Prof. Osinbanjo talks about Kinplus Technologies at Ekiti Fountain Summit",
          subtitle: "Credit: Channels Tv",
          link: "https://www.youtube.com/watch?v=KjVkKXhuvf8&t=745s",
        },

        {
          img: "oyebode.png",
          title:
            "Hon. Akintunde Oyebode on live channels interview talking about Kinplus Technologies ",
          subtitle: "Credit: Channels Tv",
          link: "https://www.youtube.com/watch?v=3bmZyhMXiEA&t=3760s",
        },

        //   {img:"",
        //    title:"",
        //    subtitle:"",
        //    link:""}
      ],
    };
  },
};
</script>
